import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useState} from "react";
import  {isLoggedIn} from "../utils/auth.service";
import {graphql, navigate} from "gatsby";
import OrdersList from "../components/orders/list";
import authHeader from "../utils/auth-header";
import LoadingSpinner from "../components/loading-spinner";
import {useTranslation} from "gatsby-plugin-react-i18next";



const Orders = () => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);


    useEffect(() => {

        if(!isLoggedIn()){
            return navigate('/');
        }

        loadDefaultFormDataFromServer();



    }, [])


    const loadDefaultFormDataFromServer = () => {



        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

     //   setLoading(true)

        // get data from GitHub api
        fetch(process.env.WASHCAR_API_URL + "/business/get-finished-orders", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setLoading(false)
                setOrders(resultData.data.finished_orders);
                //console.log(resultData.data.finished_orders);

            }) // set data for the number of stars
    }


    return (
        <Layout>

            <Seo title={t('order_history')}/>

            <h1 className="title">{t('order_history')}</h1>

            {loading ? <div className="pos-center"><LoadingSpinner /></div> : <div>

                {orders.length === 0 ? <p className={'box notification is-warning'}>{t('not_yet_finished_orders')}</p> :
                    <OrdersList orders={orders} />}

            </div>}



        </Layout>
    )
}

export default Orders

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;