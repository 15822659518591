import React from 'react';
import {Link} from 'gatsby-plugin-react-i18next';
import {useTranslation} from "gatsby-plugin-react-i18next";
import {convertPrice} from "../../utils/helper.service";
const OrdersList = (props) => {

    const {t} = useTranslation();

    return (

        <div className="card">

            <div className="card-content">

                <div className="content">

                    <div className={'list'}>
                        {props.orders.map(order => (

                            <div key={order.id} className="list-item">
                                <div className="list-item-content">
                                    <div className="list-item-title"><b>{order.invoice.invoice_number}</b></div>
                                    <div className="list-item-description">
                                        <div className="tag is-rounded"> <b>{convertPrice(order.price_in_cents_with_vat)}</b></div>
                                        <div className="tag is-rounded">{t('finished_at')}:  <b>{order.finished_at}</b></div>
                                  <div>
                                      <Link className={'button is-primary mt-2'}
                                            to={`/view-order/?id=`  + order.id}
                                      >{t('view')}</Link>
                                  </div>
                                    </div>
                                </div>
                            </div>


                        ))}
                    </div>



                </div>
            </div>
        </div>



    );
};

export default OrdersList;

/*
         state={{ orderId: order.id }}
 */